<template>
	<section>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="业务类型:">
					<el-select v-model="filters.businessType" clearable>
						<el-option
							v-for="item in businessTypeArr"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="操作时间:">
					<el-date-picker
						v-model="filters.operatingTime"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="状态:">
					<el-select v-model="filters.status" clearable>
						<el-option
							v-for="item in statusArr"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="logTableList"
			border
			highlight-current-row
			v-loading="listLoading"
			@current-change="selectCurrentRow"
			style="width: 100%;"
		>
			<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
			<el-table-column prop="OperName" label="昵称" width align="center"></el-table-column>
			<el-table-column prop="Title" label="操作详情" width align="center"></el-table-column>
			<el-table-column prop="OperTime" label="操作时间" :formatter="formatCreateTimeOperTime" width align="center"></el-table-column>
			<!-- <el-table-column prop="OperIp" label="账号IP" width align="center"></el-table-column> -->
			<el-table-column prop="IsEnable" label="状态" width="200" align="center">
				<template slot-scope="scope">
					<el-tag
						:type="scope.row.Status == 0  ? 'success' : 'danger'"
						disable-transitions
					>
						{{scope.row.Status == 0 ? "正常":"异常"}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column
				label="操作"
				width="100"
				align="center"
			>
				<template slot-scope="scope">
					<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pages.pageIndex"
				:page-sizes="pages.pageArr"
				:page-size="pages.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pages.dataCount"
			>
			</el-pagination>
		</el-col>
		<!--查看-->
		<el-dialog
			title="查看"
			:visible.sync="viewsResultDialog"
			v-model="viewsResultDialog"
			:close-on-click-modal='true'
		>
			<div>
				{{viewsResultData.JsonResult}}
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="viewsResultDialog = false">关闭</el-button>
			</div>
		</el-dialog>
	</section>
</template>

<script>
import { getSystemLogList } from "../../api/api";
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
export default {
    components: { Toolbar },
	data() {
		return {
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			filters: {
				businessType: '',
				operatingTime: [],
				status: '',
			},
			logTableList: [],//角色信息列表
			currentRow: null,
			listLoading: false,
			buttonList: [],
			businessTypeArr: [
				{name: "新增",value: 1},
				{name: "修改",value: 2},
				{name: "删除",value: 3},
				{name: "其他",value: 0},
			],
			statusArr: [
				{name: "正常",value: 0},
				{name: "异常",value: 1},
			],
			// 查看
			viewsResultDialog: false,
			viewsResultData: "",
		};
	},
	methods: {
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//获取日志分页列表
		getLogListData() {
			let _this = this;
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				businessType: this.filters.businessType,
				status: this.filters.status,
			}
			if(this.filters.operatingTime.length > 0) {
				params.startTime = this.formatCreateTime(this.filters.operatingTime[0])
				params.endTime = this.formatCreateTime(this.filters.operatingTime[1])

			}
			console.log("params",params)
			this.listLoading = true;
			getSystemLogList(params).then(res => {
				var result = res.data
				if(result.Success) {
					this.logTableList = result.Response.Data;
					this.pages.dataCount = result.Response.DataCount
				}
				this.listLoading = false;
			});
		},
		// 分页切换
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getLogListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val        
			this.getLogListData();
		},
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		//时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
			? ""
			: util.formatDate.format(new Date(row), "yyyy-MM-dd");
		},
		//时间格式化
		formatCreateTimeOperTime: function(row, column) {
			return !row.OperTime || row.OperTime == ""
			? ""
			: util.formatDate.format(new Date(row.OperTime), "yyyy-MM-dd hh:mm:ss");
		},
		// 查询
		getLogListFun(){
			this.pages.pageIndex = 1
			this.getLogListData()
		},
		// 查看
		handleClick(val) {
			this.viewsResultData = val
			this.viewsResultDialog = true
		}
	},
	mounted() {
		this.getLogListData();
		let routers = window.localStorage.router  ? JSON.parse(window.localStorage.router) : [];
		this.buttonList = getButtonList(this.$route.path, routers);
	},
};
</script>
<style lang="stylus" scoped>
	.rolesTop{
		width 100%;
		display flex;
	}
	.rolesInp{
		width 300px;
		margin-right 20px;
	}
	.pageBar{
		margin-top 15px;
	}
</style>